import React, { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { Routes } from "./routes";
import AuthRoute from "./components/auth-route";
import Loading from "./components/loading";

export const Router = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading variant="linear" />}>
                <Switch>
                    {Routes.map((route, key) => (
                        <AuthRoute
                            key={key}
                            exact
                            type={route.type}
                            component={route.component}
                            path={route.path}
                            authCheckType={route.authCheckType}
                        />
                    ))}
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
};
