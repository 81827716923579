import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    }
}));

const Loading = props => {
    const classes = useStyles();
    const { variant, color } = props;

    return variant === "linear" ? <LinearProgress color={color} /> : (
        <div className={classes.root}>
            <CircularProgress color={color} />
        </div>
    );
};

Loading.propTypes = {
    variant: PropTypes.oneOf(["linear", "circular"]),
    color: PropTypes.oneOf(["primary", "secondary"])
};

Loading.defaultProps = {
    variant: "circular",
    color: "primary"
};

export default Loading;
