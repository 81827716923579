import React, { useState, useEffect, createContext } from "react";
import Loading from "../components/loading";
import axios from "axios";
import { getUser } from "../api/user";
import { useLocalStorage } from "@rehooks/local-storage";
import { cookies } from "../constants";
import { RequestErrorInterceptor } from "../api/request-error-interceptor";

export const AuthContext = createContext({ user: {} });

const setAxiosDefaults = accessToken => {
    const authHeader = {
        Authorization: `Bearer ${accessToken}`
    };
    axios.defaults.headers.common = authHeader;
    axios.defaults.withCredentials = true;
};

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [credentials, setCredentials, deleteCredentials] = useLocalStorage(
        "credentials",
        {}
    );
    const { access_token, refresh_token } = credentials;
    const deleteDlrCode = useLocalStorage(cookies.dealer_code)[2];
    const deleteSerialNumber = useLocalStorage(cookies.serial_number)[2];
    const [loading, setLoading] = useState(true);

    const fetch = () =>
        getUser()
            .then(user => {
                setUser(user);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            });

    const setUserCredentials = credentials => {
        setAxiosDefaults(credentials.access_token);
        setCredentials(credentials);
    };

    const deleteUserCredentials = () => {
        setUser({});
        deleteDlrCode();
        deleteSerialNumber();
        deleteCredentials();
    };

    useEffect(() => {
        if (access_token) {
            setAxiosDefaults(access_token);

            const rei = RequestErrorInterceptor(
                refresh_token,
                setUserCredentials,
                deleteUserCredentials
            );

            return () => {
                axios.interceptors.response.eject(rei);
            };
        }
    }, [access_token, refresh_token]);

    const needUser = access_token && !Object.keys(user).length;
    useEffect(() => {
        if (needUser) {
            fetch();
        } else {
            setLoading(false);
        }
    }, [needUser]);

    return !loading ? (
        <AuthContext.Provider
            value={{
                user,
                setCredentials: setUserCredentials,
                deleteCredentials: deleteUserCredentials
            }}
        >
            {children}
        </AuthContext.Provider>
    ) : (
        <Loading />
    );
};
