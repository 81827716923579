import axios from "axios";
import { API } from "../constants";

export const login = (dlrCode, serialNumber) =>
    axios
        .post(API.login.url, {
            ...API.login.template,
            dealer_code: dlrCode,
            license_key: serialNumber
        })
        .then(res => res.data);

export const authorize = {
    code: "19852023"
};

export const refresh = refreshToken =>
    axios
        .post(API.login.url, {
            ...API.login.refreshTemplate,
            refresh_token: refreshToken
        })
        .then(res => res.data);

export const logout = () => axios.post(API.logout.url).then(res => res.data);

export const getUser = () => axios.get(API.user.url).then(res => res.data);
