import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router";
import { AuthContext } from "../context/auth";

const AuthRoute = ({ authCheckType, ...rest }) => {
    const { user } = useContext(AuthContext);

    if (authCheckType === "authed" && !Object.keys(user).length) {
        return <Redirect to="/login" />;
    }

    if (authCheckType === "unauthed" && !!Object.keys(user).length) {
        return <Redirect to="/" />;
    }

    return <Route {...rest} />;
};

AuthRoute.propTypes = {
    component: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired,
    authCheckType: PropTypes.oneOf(["authed", "unauthed", null])
};

export default AuthRoute;
