import { lazy } from "react";

export const Routes = [
    {
        path: "/login",
        component: lazy(() => import("./pages/Login")),
        authCheckType: "unauthed"
    },
    {
        path: "/",
        component: lazy(() => import("./pages/VehicleDataAnalysis")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/reports",
        component: lazy(() => import("./pages/AllReports")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/information",
        component: lazy(() => import("./pages/MiscellaneousInformation")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dtc",
        component: lazy(() => import("./pages/DTCHistory")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dmu/engine",
        component: lazy(() => import("./pages/EngineDMUCharts")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dmu/scr",
        component: lazy(() => import("./pages/SCRDMUCharts")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dmu/transmission",
        component: lazy(() => import("./pages/TransmissionDMUCharts")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dpd/history",
        component: lazy(() => import("./pages/DPDRegenerationHistory")),
        authCheckType: "authed"
    },
    {
        path: "/analyze/dpd/chart",
        component: lazy(() => import("./pages/DPDChart")),
        authCheckType: "authed"
    },
];
