import axios from "axios";
import { refresh } from "../api/user";

export const RequestErrorInterceptor = (
    refresh_token,
    setCredentials,
    deleteCredentials
) =>
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            const { response } = error;
            if (response) {
                const { status } = response;
                switch (status) {
                    case 401:
                        return !error.config.url.match(/\/oauth\/token/)
                            ? refresh(refresh_token)
                                  .then(credentials => {
                                      setCredentials(credentials);
                                      const request = error.config;
                                      // Otherwise, config will recall the previous setting.
                                      Object.assign(
                                          request.headers,
                                          axios.defaults.headers.common
                                      );
                                      return axios.request(request);
                                  })
                                  .catch(error => {
                                      deleteCredentials();
                                      return Promise.reject(status);
                                  })
                            : Promise.reject(status);
                    case 400:
                        deleteCredentials();
                        return Promise.reject(response.data.hint);
                    default:
                        return Promise.reject(status);
                }
            }

            return Promise.reject(error);
        }
    );
