import env from "./environment";

const baseUrl = env.PTI_API_BASE_URL;

export const API = {
    baseUrl,
    login: {
        url: baseUrl + "/oauth/token",
        template: {
            client_id: env.PTI_AUTH_CLIENT_ID,
            client_secret: env.PTI_AUTH_CLIENT_KEY,
            grant_type: "gidss_license",
            dealer_code: null,
            license_key: null,
            authorized: false
        },
        refreshTemplate: {
            client_id: env.PTI_AUTH_CLIENT_ID,
            client_secret: env.PTI_AUTH_CLIENT_KEY,
            grant_type: "refresh_token",
            refresh_token: null
        }
    },
    logout: { url: baseUrl + "/accounts/v1/auth/logout" },
    user: { url: baseUrl + "/accounts/v1/auth" },
    drm: {
        search: {
            url: baseUrl + "/diagnostics/v1/g/dat/search",
        },
        download: { url: baseUrl + "/diagnostics/v1/g/dat/download" }
    },
    dat: {
        url: baseUrl + "/diagnostics/v1/dat",
        upload: { url: baseUrl + "/diagnostics/v1/dat/upload" }
    },
    authorize: {
        code: "1234"
    }
};

export const cookies = {
    token: "laravel_token",
    refresh_token: "laravel_refresh_token",
    serial_number: "gidss_serial_number",
    dealer_code: "dealer_code",
    country_no: "country_number",
    authorized: "is_auth"
};

export const axiosConfiguration = {
    responseType: "json",
    timeout: 120000,
    validateStatus: (status) => {
        return status == 200 || status == 422;
    },
};